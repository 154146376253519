export default {
  namespaced: true,
  state: {
    // qrcode: '',
  },
  getters: {

  },
  mutations: {
    // setQrcode (state, data) {
    //   state.qrcode = data
    // },

  },
  actions: {
    // 방문객 초대 상태 및 개인정보 보유 동의 확인
    async getVisitorCheck ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}visitor/${payload.seq}/check`)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 개인정보수집이용 동의여부
    async postVisitorAgreement ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.post(`${this._vm.APP_API_URL}visitor/${payload.seq}/agreement`, payload.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // QR 생성 / 재생성
    async getVisitorQrcode ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}visitor/${payload.seq}/qr`)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // QR을 제외한 방문객 정보 조회
    async getVisitorHome ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}visitor/${payload.seq}/home`)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
