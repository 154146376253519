<template>
  <div v-show="display" class="context-menu">
    <ul v-mousedown-outside="close">
      <li v-if="placeholder" class="placeholder">{{placeholder}}</li>
      <template v-for="item in items">
        <li v-if="Array.isArray(item)"
        :key="'item-'+item"
        @click.stop="setValue(item)">
          {{item}}
        </li>
        <li v-else
        :key="'item-'+item[trackBy]"
        @click.stop="setValue(item[trackBy])">
          {{item[label]}}
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ConextMenu',
  inheritAttrs: false,
  props: {
    value: { type: null, default: null },
    id: { type: String, default: '' },
    items: { type: Array },
    trackBy: { type: String, default: 'id' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: 'name' },
    display: { type: Boolean, default: false },

  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {

  },
  methods: {
    close () {
      this.$emit('close')
    },
    setValue (value) {
      this.$emit('input', value)
      this.close()
    },
    input (event) {
      this.$emit('input', event.target.value)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
  watch: {
    value: {
      handler (newv) {
        this.$emit('change', newv)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.context-menu {
  position: absolute;
  z-index: 100;
  margin-top: 4px;
  .bg {
    width: 100vw;
    height:calc(var(--dvh) * 100); // fallback
    height:100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }
  ul {
    min-width: 100px;
    border-radius: 12px;
    box-shadow: 0px 4px 72px 0px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(60px);
    li {
      background: rgba(255, 255, 255, 0.90);
      padding: 11px 16px 10px 16px;
      border-bottom: 1px solid  rgba(60, 60, 67, 0.20);

      color: var(--Label-Color-Light-Primary, #000);
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 129.412% */
      letter-spacing: -0.408px;
    }
    li.placeholder {
      color: var(--gray-50, #8F8F8F);
      font-size: 15px;
    }
    li:first-child {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    li:last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-bottom: 0;
    }
  }
}
</style>
