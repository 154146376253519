export default {
  namespaced: true,
  state: {
    floors: [],
    dates: [],
    meetingRooms: [],
  },
  getters: {
    floors: (state) => state.floors,
    dates: (state) => state.dates,
    meetingRooms: (state) => state.meetingRooms,
  },
  mutations: {
    setFloors (state, floors) {
      state.floors = floors || []
    },
    setDates (state, dates) {
      state.dates = dates || []
    },
    setMeetingRooms (state, meetingRooms) {
      state.meetingRooms = meetingRooms || []
    },
  },
  actions: {
    async getAvaliableFloors ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/building/${payload.buildingNo}/meeting-room/available-floors`,
        )
        commit('setFloors', response?.data?.floors)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAvaliableDates ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/building/${payload.buildingNo}/floor/${payload.floorCode}/meeting-room/available-dates`,
        )
        commit('setDates', response?.data?.dates)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getMeetingRooms ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}reservation/building/${payload.buildingNo}/floor/${payload.floorCode}/meeting-rooms`,
          payload,
        )
        commit('setMeetingRooms', response?.data?.meetingRooms)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}reservation/meeting-room`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteReservation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.APP_API_URL}reservation/meeting-room/${payload.meetingRoomReservationNo}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },

  /**
   * @deprecated
   */
  async getReservation ({ dispatch, commit }, payload) {
    let response = null
    try {
      response = await this._vm.get(
        `${this._vm.APP_API_URL}reservation/meeting-room/${payload.reservationNo}`,
      )
    } catch (error) {
      if (error.response) {
        response = error
      } else {
        throw error
      }
    }
    return response
  },
}
