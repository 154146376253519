import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

const Error404 = () => import('../components/common/error/Error404.vue')
const Error403 = () => import('../components/common/error/Error403.vue')
// const Error403 = () => import('../components/common/error/Error403.vue')
const AppMain = () => import('../components/AppMain.vue')
const Login = () => import('../components/login/Login.vue')
const RecoveryPassword = () => import('../components/login/RecoveryPassword.vue')
const NewPassword = () => import('../components/login/NewPassword.vue')
const Welcome = () => import('../components/login/Welcome.vue')
const MyPage = () => import('../components/pages/MyPage.vue')
const MeetingRoom = () => import('../components/pages/MeetingRoom.vue')
const IoT = () => import('../components/pages/IoT.vue')

/* 방문객 초대 , 내역 페이지 */
const GuestInvitePage = () => import('../components/pages/GuestInvitePage.vue')
/* 알림톡 통해 들어온 동의하는 화면 */
const GuestInviteAgreementPage = () => import('../components/guest/GuestInviteAgreementPage.vue')
/* 알림톡 통해 들어온 Qr코드 페이지 */
const GuestInviteQrPage = () => import('../components/guest/GuestInviteQrPage.vue')
/* 에러 페이지 2종(시간만료,예약취소) */
const GuestInviteErrorPage = () => import('../components/guest/GuestInviteErrorPage.vue')
// 마이페이지 > 비밀번호 변경
// 비밀번호 검증
const ConfirmNowPassword = () => import('../components/pages/ConfirmNowPassword.vue')
// 비밀번호 변경
const ChangePassword = () => import('../components/pages/ChangePassword.vue')

// 네트워크 인증 성공 시 성공 안내 페이지
const NetworkConnect = () => import('../components/guest/NetworkConnect.vue')

Vue.use(VueRouter)
const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '*', // next({ name: "Error403", params: [to.path], replace: true }) // 주소 유지하며 403 페이지 노출하는 방법
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'Login' },
  },
  {
    path: '/home',
    name: 'Home',
    component: AppMain,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor: '#1E1E1E',
      themeColor: '#1E1E1E',
    },
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: MyPage,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor:
        'linear-gradient(to bottom, #1E1E1E 0,#1E1E1E 320px,#F8F7EF 320px, #F8F7EF 100%)',
      themeColor: '#1E1E1E',
    },
  },
  {
    path: '/meeting-room',
    name: 'MeetingRoom',
    component: MeetingRoom,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '/iot',
    name: 'IoT',
    component: IoT,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      // 이미 로그인 했으면 홈으로
      if (isLoggedIn()) {
        next({ name: 'Home', query: to.query })
        return
      }
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('member/refresh')) {
        // 리프레시 성공 시 홈으로
        next({ name: 'Home', query: to.query })
        return
      }
      // 로그인 안 됨
      // 로그인 페이지로 입장
      next()
    },
    meta: {
      requiresAuth: false,
      fullScreen: false,
      redirect: '/home',
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      redirect: '/',
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
    beforeEnter: async (to, from, next) => {
      let result
      if (await store.dispatch('member/refresh')) {
        result = await store.dispatch('member/logout')
      }
      if (result == 'error') {
        alert('로그아웃에 실패 했습니다. 인터넷 연결을 확인 해 주세요.')
        next(from.fullPath)
      } else {
        if (to.query.returnPage) {
          next({
            path: '/login',
            query: { redirect: from.fullPath },
          })
        } else {
          next('/login')
        }
      }
    },
  },
  {
    path: '/recovery/password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '/recovery/new-password/:token',
    name: 'NewPassword',
    component: NewPassword,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  // from email (no display back button)
  {
    path: '/password/setting/:token',
    redirect: {
      name: 'NewPassword',
    },
  },
  // from first login (display back button)
  {
    path: '/password/reset/:token',
    redirect: (to) => ({
      path: '/recovery/new-password/:token',
      query: { back: 1 },
    }),
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      requiresAuth: true,
      fullScreen: true,
      backgroundColor: '#1E1E1E',
      themeColor: '#1E1E1E',
    },
  },

  // 방문자 초대 및 내역 페이지
  {
    path: '/invite/guest/',
    name: 'GuestInvitePage',
    component: GuestInvitePage,
    meta: {
      requiresAuth: true,
      fullScreen: true,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },

  // ##########시작 여기는 알림톡 통해 유입된 페이지입니다.
  // 방문자 초대 메시지 통해 최초로 들어가지는 게이트 화면
  {
    path: '/invite/qr/agreement',
    name: 'GuestInviteAgreementPage',
    component: GuestInviteAgreementPage,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  // 방문객 QR 페이지
  {
    path: '/invite/qr/qrcode',
    name: 'GuestInviteQrPage',
    component: GuestInviteQrPage,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  // 초대 (시간만료,취소)페이지
  {
    path: '/invite/qr/error/:code', // expiry,cancel
    name: 'GuestInviteErrorPage',
    component: GuestInviteErrorPage,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  // ##########끝 여기는 알림톡 통해 유입된 페이지입니다.
  {
    path: '/mypage/confirm-password',
    name: 'ConfirmNowPassword',
    component: ConfirmNowPassword,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '/mypage/change-password/:token',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
  {
    path: '/network-connect',
    name: 'NetworkConnect',
    component: NetworkConnect,
    meta: {
      requiresAuth: false,
      fullScreen: false,
      backgroundColor: '#F8F7EF',
      themeColor: '#F8F7EF',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
// 엑세스 토큰 보유여부 확인
const isLoggedIn = function () {
  return !!store.state.member.accessToken
}
// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 뒤로가기 버튼 사이트 이탈 방지
  store.commit('setHasHistory', from.path != '/')
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLoggedIn()) {
      // if (권한검사실패시) { next({ name: "Error403", params: [to.path], replace: true }); return }
      next()
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('member/refresh')) {
        // 리프레시 시도 성공
        // 페이지 접근 권한 확인 (구현 안 함)
        next()
        // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
      }
    }
    // 권한이 필요 없는 페이지
  } else {
    next()
  }
})

export default router
