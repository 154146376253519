import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import 'type-hangul'

import isOutside from './directive/isOutside'
import validity from './directive/Validity'
import dragscroll from './directive/dragscroll'
import Popup from './components/common/Popup'
import BottomSheet from './components/common/BottomSheet'
import ContextMenu from './components/common/ContextMenu'
import Tooltip from './components/common/Tooltip'
import SpInput from './components/common/input/SpInput'
import SpSelect from './components/common/input/SpSelect'
import SpSwitchCheckbox from './components/common/input/SpSwitchCheckbox'
import SpTabbedPanel from './components/common/input/SpTabbedPanel'
import BlindController from './components/common/IoTComponents/BlindController.vue'
import SpCheckbox from './components/common/input/SpCheckbox.vue'

import './mixin/KonvaLibs'
import './mixin/Common'
import './assets/scss/common.scss'

Vue.config.productionTip = false
// Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(isOutside)
Vue.use(validity)
Vue.use(dragscroll)
Vue.use(PortalVue)
Vue.use(VCalendar)

Vue.component('Popup', Popup)
Vue.component('BottomSheet', BottomSheet)
Vue.component('ContextMenu', ContextMenu)
Vue.component('Tooltip', Tooltip)
Vue.component('SpInput', SpInput)
Vue.component('SpSelect', SpSelect)
Vue.component('SpSwitchCheckbox', SpSwitchCheckbox)
Vue.component('SpTabbedPanel', SpTabbedPanel)
Vue.component('BlindController', BlindController)
Vue.component('SpCheckbox', SpCheckbox)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: (h) => h(App),
})

router.onReady(() => {
  v.$mount('#app')
})
