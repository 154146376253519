<template>
  <input
  ref="input"
  :type="type"
  :value="formattedValue"
  :data-value="value"
  :id="uuid"
  @input="input"
  v-on="lisnters"
  v-bind="attrs"
  :disabled="disabled"/>
</template>

<script>
export default {
  name: 'SpInput',
  inheritAttrs: false,

  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    type: { type: String, default: 'text', required: false },
    disabled: { type: Boolean, default: false },
    breakPoints: { type: Array, default: () => [] },
    delimiter: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  mounted () {

  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
    focus () {
      this.$refs.input.focus()
    },
  },
  computed: {
    isFocused: () => document.activeElement === this?.$refs?.input,
    lisnters () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
    formattedValue () {
      let formatted = this.value
      if (this.breakPoints.length && this.delimiter != '') {
        const splitStringByBreakPoints = (str, breakPoints) => {
          const result = []
          let startIndex = 0
          for (let i = 0; i < breakPoints.length; i++) {
            const breakPoint = breakPoints[i]
            const substring = str.substr(startIndex, breakPoint)
            if (substring.length) {
              result.push(substring)
            }
            startIndex += breakPoint
          }
          const remaining = str.substr(startIndex)
          if (remaining.length) {
            result.push(remaining)
          }
          return result
        }
        const realNumber = this.value.replaceAll(this.delimiter, '')
        const delimiteredNumber = splitStringByBreakPoints(realNumber, this.breakPoints)
        formatted = delimiteredNumber.join(this.delimiter)
      }
      return formatted
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  caret-color: #F37324;
  position: relative;
  padding: 16px 11px;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  width:100%;
  height:50px;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  outline: none;
  background: #FFF;
  color: #1E1E1E;
  border-radius: 4px;
  &:disabled {
    // background-color: transparent;
    background-color: #ECEBE3 !important;
    -webkit-opacity: 1;
    -webkit-text-fill-color: #1E1E1E;
    opacity:1;
  }
  &::placeholder {
    color: #8F8F8F;
  }
}
input[type=number] {
  text-align: right;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
input[type=search] {
  /* width: calc(100% - 4px); */
}
input:focus {
  border-color: #1E1E1E;
  // animation: shadow 0.1s ease-in-out forwards;
  &.warning {
    border-color: #CF2027;
  }
  &.suceess {
    border-color: #32B478;
  }
}
// @keyframes shadow {
//   to {
//     box-shadow: 0 0 1px var(--theme-primary-color);
//     /* box-shadow: 0 0 6px red; */
//   }
// }
.hide-caret {
  caret-color: transparent !important;
}
</style>
