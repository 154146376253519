<template>
  <div class="blind-control" :class="{disabled}">
    <div
    ref="track"
    @pointerdown.stop="start"
    class="track"
    :style="{backgroundImage: trackPosition(index)}">
      <button class="knob" :style="{left: knobPosition(index)}"></button>
      <div class="step" v-for="i in steps.length" :key="`step-${i}`"></div>
    </div>
    <div class="labels">
      <div v-for="step in steps" class="label" :key="`label-${step}`">{{step}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlindController',
  props: {
    steps: { type: Array, required: true },
    value: { type: null, default: null },
    id: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data () {
    return {
      uuid: null,
      MUTEX: false,
      index: 0,
      START_X: false,
      START_Y: false,
      direction: null,
    }
  },
  computed: {
    knobPosition () {
      return (i) => {
        return `calc(calc(13.5px + calc(100% - 27px) / ${this.steps.length - 1} * ${i}) + ${i == 0 ? '-2' : i == (this.steps.length - 1) ? '+2' : '0'}px)`
      }
    },
    trackPosition () {
      const disabledColor = '#8F8F8F'
      const normalColor = '#F37324'
      const color = this.disabled ? disabledColor : normalColor
      return (i) => {
        return `linear-gradient(to right, ${color} calc(13.5px + calc(100% - 27px) / ${this.steps.length - 1} * ${i}), #fff0 0px, #fff0  100%)`
      }
    },
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
    const vtoi = this.steps.indexOf(this.value)
    this.index = vtoi == -1 ? 0 : vtoi
  },
  mounted () {
    window.addEventListener('pointerup', this.end)
    window.addEventListener('pointermove', this.move)
  },
  beforeDestroy () {
    window.removeEventListener('pointerup', this.end)
    window.removeEventListener('pointermove', this.move)
  },
  methods: {
    start (e) {
      this.MUTEX = true
      this.direction = null
      this.START_X = e.clientX
      this.START_Y = e.clientY
    },
    __calculationIndex (e) {
      const TRACK_MAX_WIDTH = this.$refs.track.getBoundingClientRect().width - 27 // TRACK PADDING (BOTH 27px)
      const TRACK_START_POS_X = this.$refs.track.getBoundingClientRect().left + 13.5 // TRACK PADDING (LEFT 13.5px)
      let DIFF = e.clientX - TRACK_START_POS_X
      // min max clipping
      if (DIFF < 0) DIFF = 0
      if (DIFF > TRACK_MAX_WIDTH) DIFF = TRACK_MAX_WIDTH
      this.index = Math.round(DIFF / TRACK_MAX_WIDTH * (this.steps.length - 1))
    },
    move (e) {
      if (this.MUTEX && this.direction == null) {
        const DIRECTION_THRESHOLD = 10
        const DIFF_X = Math.abs(this.START_X - e.clientX)
        const DIFF_Y = Math.abs(this.START_Y - e.clientY)
        if (DIFF_X > DIRECTION_THRESHOLD || DIFF_Y > DIRECTION_THRESHOLD) {
          if (DIFF_X < DIFF_Y) {
            this.direction = 'virtical'
          } else {
            this.direction = 'horizontal'
          }
        }
      }
      if (this.MUTEX) {
        if (this.direction == 'horizontal') {
          this.__calculationIndex(e)
        }
      }
    },
    end (e) {
      if (this.MUTEX && this.direction != 'virtical') {
        this.__calculationIndex(e)
        this.$emit('input', this.steps[this.index])
      }
      this.direction = null
      this.MUTEX = false
    },

  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        const vtoi = this.steps.indexOf(newValue)
        this.index = vtoi == -1 ? 0 : vtoi
        this.$emit('change', {
          newValue,
          oldValue,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.blind-control {
  width: 100%;
  &.disabled {
    touch-action: none;
    pointer-events: none;
  }
  .track {
    position: relative;
    display:flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 8px;
    padding: 0 13.5px;
    margin-bottom: 18px;
    border-radius: 4px;
    background:rgba(0, 0, 0, 0.10);
    cursor: pointer;
    touch-action: pan-y;
    .step {
      opacity: 0.1;
      background: #1E1E1E;
      width: 1px;
      &:first-of-type {
        opacity: 0;
      }
      &:last-of-type {
        opacity: 0;
      }
    }
    .knob {
      position:absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      margin-left: -13.5px;
      border-radius: 14px;
      background-color:#fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.10), 0px 0.5px 4px rgba(0, 0, 0, 0.12);
    }
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 300%;
        border-radius: 20px;
        // border: 1px dashed #ccc; /* for debug */
        // background-color: rgba(255,0,0,0.1); /* for debug */
    }
  }
  .labels {
    display:flex;
    justify-content: space-between;
    .label {
      width:27px;
      color: 787878;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}

</style>
