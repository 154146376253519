
export default {
  namespaced: true,
  state: {
    accessToken: null,
    // member = 임시데이터
    member: {
      memberNo: null,
      loginId: null,
      memberName: null,
      mobilePhoneNumber: null,
      mainMent: null,
      buildings: [],
      lowFloor: {
        buildingNo: null,
        buildingName: null,
        displayBuildingNameKo: null,
        displayBuildingNameEn: null,
        buildingAddress: null,
        buildingDetailAddress: null,
        buildingDescription: null,
        floorCode: null,
        floorDisplayName: null,
      },
    },
    currentBuilding: {
      buildingNo: null,
      buildingName: null,
      displayBuildingNameKo: null,
      displayBuildingNameEn: null,
      buildingAddress: null,
      buildingDetailAddress: null,
      buildingDescription: null,
      floorCode: null,
      floorDisplayName: null,
    },
    bookmarkFloor: {
      buildingNo: null,
      buildingName: null,
      displayBuildingNameKo: null,
      displayBuildingNameEn: null,
      buildingAddress: null,
      buildingDetailAddress: null,
      buildingDescription: null,
      floorCode: null,
      floorDisplayName: null,
    },
    myOffices: [],
    iot: [],
  },
  getters: {
    member: (state) => state.member,
    currentBuilding: (state) => state.currentBuilding,
    myOffices: (state) => state.myOffices,
    bookmarkFloor: (state) => state.bookmarkFloor,
    accessToken: (state) => state.accessToken,
    iot: (state) => state.iot,
  },

  mutations: {
    setAccessToken (state, token) {
      state.accessToken = token
    },
    setMember (state, member) {
      state.member = { ...member }
    },
    setCurrentBuilding (state, buildingInfo) {
      state.currentBuilding = { ...buildingInfo }
    },
    setBookmarkFloor (state, bookmarkFloor) {
      state.bookmarkFloor = { ...bookmarkFloor }
    },
    setMyOffices (state, myOffices) {
      state.myOffices = myOffices || []
    },
    setIot (state, iot) {
      state.iot = iot || []
    },
  },
  actions: {
    async login ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.APP_API_URL}login`, payload)
        // first login (password reset)
        if (response.data.token?.passwordToken) {
          return response
        }
        commit('setAccessToken', response.data.token.accessToken)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // refresh-token 삭제 (로그아웃)
    async logout ({ commit, getters, state }) {
      try {
        const payload = {
          accountNo: getters.accountNo,
        }
        await this._vm.post(`${this._vm.APP_API_URL}logout`, payload)
      } catch (error) {
        return 'error'
      } finally {
        commit('setAccessToken', null)
      }
      // 로그아웃 화면 결정용
      return 'ok'
    },

    // 토큰 갱신
    async refresh ({ dispatch, commit }) {
      try {
        const response = await this._vm.post(`${this._vm.APP_API_URL}refresh`)
        commit('setAccessToken', response.data.accessToken)
        return true
      } catch (error) {
        return false
      }
    },
    async memberProfile ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}home`, // 권한관리 추가되면 profile api 만들어서 바꿔껴야함
        )
        commit('setMember', response.data)
        // 메인에 표기될 현재 건물 및 층 정보
        let bookmarkBuildingFloor = response.data.buildings.find((i) => {
          return i.bookmarkFloor
        })
        if (!bookmarkBuildingFloor) {
          bookmarkBuildingFloor = response.data.lowFloor
          commit('setBookmarkFloor', {
            buildingNo: null,
            buildingName: null,
            displayBuildingNameKo: null,
            displayBuildingNameEn: null,
            buildingAddress: null,
            buildingDetailAddress: null,
            buildingDescription: null,
            floorCode: null,
            floorDisplayName: null,
          })
        } else {
          bookmarkBuildingFloor.floorCode
            = bookmarkBuildingFloor.bookmarkFloor.floorCode
          bookmarkBuildingFloor.floorDisplayName
            = bookmarkBuildingFloor.bookmarkFloor.floorDisplayName
          delete bookmarkBuildingFloor.bookmarkFloor
          delete bookmarkBuildingFloor.floors
          commit('setBookmarkFloor', bookmarkBuildingFloor)
        }
        commit('setCurrentBuilding', bookmarkBuildingFloor)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async recoveryPasswordByEmailAddress ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}recovery/password/email`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async passwordReset ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.APP_API_URL}password/reset/${payload.token}`,
          payload,
        )
        commit('setAccessToken', response.data.token.accessToken)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async hasMeetingRoom ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}home/building/${payload.buildingNo}/has-meeting-rooms`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async hasInvitation ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}home/has-invitation`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async myOfficeFloors ({ dispatch, commit }, payload) {
      let response = null
      commit('setBookmarkFloor', {
        buildingNo: null,
        buildingName: null,
        displayBuildingNameKo: null,
        displayBuildingNameEn: null,
        buildingAddress: null,
        buildingDetailAddress: null,
        buildingDescription: null,
        floorCode: null,
        floorDisplayName: null,
      })
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}mypage/my-offices`,
        )
        // set hasbookmarkfloor
        const myOffices = response.data.myOffices.filter(i => {
          return i.floorCode !== 'F001'
        })
        commit('setMyOffices', myOffices)
        if (myOffices.length > 0) {
          const bookmarkFloor = myOffices.find((i) => {
            return i.bookmarkFloorYn === 'Y'
          })
          if (bookmarkFloor) {
            commit('setBookmarkFloor', bookmarkFloor)
          } else {
            commit('setCurrentBuilding', myOffices[0])
          }
        }
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async updateMyOffice ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.APP_API_URL}mypage/bookmark/floor`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getQr ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}qr`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getMyPageMemberInfo ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}mypage`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getPasswordResetTokenConfirm ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}recovery/password/email/${payload.token}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postConfirmPassword ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}mypage/confirm-password`,
          payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postChangePassword ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}mypage/change-password/${payload.token}`,
          payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getHomeIot ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}home/iot`, payload)
        commit('setIot', response.data.iot)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
