export default {
  namespaced: true,
  state: {
    countryCodes: [],
  },
  getters: {

  },
  mutations: {
    setCountryCode (state, data) {
      state.countryCodes = data
    },
  },
  actions: {
    async getCountryCode ({ dispatch, commit }, payload) {
      let response
      try {
        response = await this._vm.get(`${this._vm.API_URL}common/common-code`, payload)

        const customList = response.data.list.map(item => {
          // 새로운 키 추가
          return {
            ...item,
            customLabel: `${item.code} ${item.displaycodename}`,
          }
        })

        commit('setCountryCode', customList)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
