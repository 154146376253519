
<template>
  <div class="bottom-sheet-wrap">
    <div class="bg" @click.once="close" :class="{active}"></div>
    <div class="bottom-sheet-container" ref="bottomSheet" :class="{active}">
      <div class="header">
        <template v-if="hasButtons">
          <button class="close-button" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M14 2L2 14" stroke="#1E1E1E" stroke-width="2" stroke-linecap="square"/>
              <path d="M14 14L2 2" stroke="#1E1E1E" stroke-width="2" stroke-linecap="square"/>
            </svg>
          </button>
          <div class="button-slots">
            <slot name="sheet-button-area">
            </slot>
          </div>
        </template>
        <button v-else class="close-button big" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 2.70726L19.4142 2.00015L18 0.585938L17.2929 1.29304L10 8.58594L2.70711 1.29304L2 0.585938L0.585787 2.00015L1.29289 2.70726L8.58578 10.0002L1.29289 17.293L0.585785 18.0001L2 19.4144L2.70711 18.7073L10 11.4144L17.2929 18.7073L18 19.4144L19.4142 18.0002L18.7071 17.293L11.4142 10.0002L18.7071 2.70726Z" fill="#1E1E1E"/>
          </svg>
        </button>
      </div>
      <div class="bottom-sheet-body">
        <div v-show="title" v-html="title" class="bottom-sheet-title"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomSheet',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      active: false,
    }
  },
  computed: {
    hasButtons () {
      return !!this.$slots?.['sheet-button-area']
    },
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.active = true
      }, 1)
    })
    if (window.location.hash != '#sheet') {
      window.history.pushState({}, '', `${window.location.pathname}#sheet`)
    }
    window.addEventListener('popstate', this.close)
  },
  methods: {
    close (e) {
      document?.activeElement?.blur()
      if (e?.type !== 'popstate') {
        window.history.go(-1)
      }
      this.active = false
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    },
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.close)
  },
}
</script>

<style lang="scss" scoped>
::v-deep sup {position: relative; top:0.2em; vertical-align: top; font-size: 0.6em; font-weight: 400;}
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color .3s linear;
  z-index: 101;
  &.active {
    background-color: rgba(0,0,0,0.3);
  }
  z-index: 101;
}
.bottom-sheet-wrap {
  position: fixed !important;
  height: calc(var(--dvh) * 100); // fallback
  height: 100dvh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-sheet-container {
  position: absolute;
  transition: bottom .3s ease-out;
  bottom: -100%;
  &.active {
    bottom: 0;
  }
  z-index: 103;
  width: 100%;
  max-width: 600px;
  height: calc(calc(var(--dvh) * 100) - 45px); // fallback
  height: calc(100dvh - 45px);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #F8F7EF;
  padding: 16px 20px;
  box-sizing: border-box;
  .header {
    // background: red;
    display: flex;
    height:44px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &:has(.close-button.big) {
      justify-content: end;
      margin-bottom: 0;
    }
    .close-button {
      display: flex;
      width: 20px;
      height:20px;
      justify-content: center;
      align-items:center;
      &.big {
        display: flex;
        width: 24px;
        height: 24px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
    .button-slots {
      & > a, & > button {
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 0 4px;
        &.coral {
          color :#CF2027;
        }
        &.sky {
          color: #4C79D3;
        }
      }
    }
  }
  .bottom-sheet-body {
    box-sizing: border-box;
    // padding: 40px 20px;
    min-width: 310px;
    .bottom-sheet-title {
      color: #1E1E1E;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 24px;
    }
  }
}
</style>
