<template>
  <div class="wrap-layout">
    <splash v-show="splash" />
    <div v-show="$store.state.requested && !LOADING_SRCEEN_FORCE_OFF" class="loading">
      <div v-show="debouncedLoading" class="circle"></div>
    </div>
    <router-view :key="$route.path" v-show="network == 'online'" />
    <error-network v-show="network == 'offline'"/>
    <sp-modals />
    <sp-toasts />
  </div>
</template>
<script>
import Splash from './components/common/Splash.vue'
import SpModals from './components/common/SpModals.vue'
import SpToasts from './components/common/SpToasts.vue'
import ErrorNetwork from './components/common/error/ErrorNetwork.vue'
export default {
  name: 'App',
  data () {
    return {
      splash: false,
      debouncedLoading: 0,
      network: 'online',
    }
  },
  methods: {
    connection (e) {
      this.network = e.type
    },
    keyboard (e) {
      // android prevent under scrolling of keyboard
      if (!(e.target instanceof HTMLInputElement)) {
        document.activeElement.blur()
      }
    },
    init () {
      const params = new URLSearchParams(location.search)
      this.splash = !!params.get('splash')
      this.resizeWindow()
      this.$nextTick(() => {
        if (this.splash) {
          document.querySelector('meta[name="theme-color"]').setAttribute('content', '#1E1E1E')

          setTimeout(() => {
            document.querySelector('meta[name="theme-color"]').setAttribute('content', this.$route.meta.themeColor)
            this.splash = false
          }, 1000)
        }
        document.addEventListener('pointerdown', this.keyboard)
        window.addEventListener('offline', this.connection)
        window.addEventListener('online', this.connection)
        window.addEventListener('scroll', this.preventScrollingiOSPWA)
        window.addEventListener('resize', this.resizeWindow)
        window.visualViewport.addEventListener('resize', this.resizeVisualViewport)
      })
    },
  },
  created () {
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    document.removeEventListener('pointerdown', this.keyboard)
    window.removeEventListener('offline', this.connection)
    window.removeEventListener('online', this.connection)
    window.removeEventListener('scroll', this.preventScrollingiOSPWA)
    window.removeEventListener('resize', this.resizeWindow)
    window.visualViewport.removeEventListener('resize', this.resizeVisualViewport)
  },
  components: {
    Splash,
    SpModals,
    SpToasts,
    ErrorNetwork,
  },
  computed: {},
  watch: {
    '$route.meta.backgroundColor': {
      deep: true,
      immediate: true,
      handler: function (color = '#1E1E1E') {
        this.$nextTick(() => {
          document.documentElement.style.background = color
          document.querySelector('meta[name="theme-color"]').setAttribute('content', this.$route.meta.themeColor)
        })
      },
    },
    // 현재 총 axios 요청 갯수 (before responsed)
    '$store.state.requested': {
      deep: true,
      immediate: true,
      handler: function (newv) {
        if (newv == 0) {
          this.debounceForceCancellation('requested')
          this.debouncedLoading = newv
          return
        }
        this.debounce(
          'requested',
          () => { this.debouncedLoading = newv },
          500, // 0.5초 이상 네트워크 지연시에만 로딩 출현
        )
      },
    },
  },
}
</script>
<style lang="scss">
* {
  font-family: 'Pretendard';
  font-size: 15px;
  letter-spacing: -0.2px;
  font-weight: 400;
  color: black;
  // scroll-behavior: smooth;
}
html {
  overscroll-behavior: contain;
}
html, body {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
.wrap-layout{
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 600px;
  // overflow: auto;
  &:has(.bg) {
    overflow: hidden;
  }
}
// PC ONLY
@media screen and (min-width: 769px) {

}
// MOBILE ONLY
@media screen and (max-width:768px) {

}

</style>
<style lang="scss" scoped>
  .loading {
    position:fixed;
    width:100%;
    height:100%;
    top: 0;
    left: 0;
    z-index: 14;
    // background: #ff000033;
  }
  @keyframes loading {
    0% {
      // backdrop-filter: blur(0px);
      opacity: 0;
    }
    50% {
      opacity: .5;
      // backdrop-filter: blur(2px);
    }
    100% {
      opacity: 1;
      // backdrop-filter: blur(0px);
    }
  }
  .circle {
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin : auto;
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    border-top-color: #F37324;
    // animation: spin 1s ease-in-out infinite;
    animation: spin .5s linear infinite;
  }
  @keyframes spin {
    to { transform: rotate(360deg);}
  }

</style>
