<template>
<div class="inputbox" :class="`theme-${theme}`">
  <label :for="uuid">
    <input
    v-if="Array.isArray(checked)"
    type="checkbox"
    :id="uuid"
    :value="value"
    :checked="checked.includes(value)"
    @input="multiCheck"
    v-on="listeners"
    v-bind="attrs" />
    <input
    v-else
    type="checkbox"
    :id="uuid"
    :value="value"
    :checked="checked == trueValue"
    @input="singleCheck"
    v-on="listeners"
    v-bind="attrs" />
    <span v-if="text">{{ text }}</span>
  </label>
</div>
</template>

<script>
export default {
  name: 'SpSwitchCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    value: { default: undefined },
    trueValue: { default: true },
    falseValue: { default: false },
    id: { type: String, default: '' },
    text: { type: String, default: String },
    checked: { required: false },
    theme: {
      type: String,
      default: 'primary',
      validator (value) {
        return ['primary', 'secondary', 'tertiary'].includes(value)
      },
    },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }

    if (Array.isArray(this.checked)) {
      this.$emit('input', this.checked)
    } else {
      this.$emit('input', this.checked == this.trueValue ? this.trueValue : this.falseValue)
    }
  },
  methods: {
    singleCheck () {
      if (this.checked != this.trueValue) {
        this.$emit('input', this.trueValue)
      } else {
        this.$emit('input', this.falseValue)
      }
    },
    multiCheck () {
      const checked = [].concat(this.checked)
      if (checked.includes(this.value)) {
        checked.splice(checked.indexOf(this.value), 1)
      } else {
        checked.push(this.value)
      }
      this.$emit('input', checked)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.inputbox label {
  cursor: pointer;
}

input[type="checkbox"] {
  appearance: none;
  position: relative;
  border-radius: 999px;
  background: #F37324;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  width: 64px;
  height: 32px;
  padding: 8px 6px;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  background-color: #D0D0D0;
}

input[type="checkbox"]::before {
  content: "";
  width: 20px;
  height: 20px;
  // flex-shrink: 0;
  position: absolute;
  left: 6px;
  border-radius: 100%;
  background-color:#fff;
  transition: left 150ms ease-out;
}

input[type="checkbox"]:checked {
  background-color: #F37324;
}

input[type="checkbox"]:checked::before {
  background-color: #fff;
  left: 38px;
}

</style>
