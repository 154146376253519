const getDefaultState = () => {
  return {
    iotDeviceTypes: [],
    devices: [],
    floorIndoorTemperature: null,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    iotDeviceTypes: (state) => state.iotDeviceTypes,
    devices: (state) => state.devices,
    floorIndoorTemperature: (state) => state.floorIndoorTemperature,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setIotDeviceTypes (state, iotDeviceTypes) {
      state.iotDeviceTypes = iotDeviceTypes || []
    },
    setIotDevices (state, devices) {
      state.devices = devices || []
    },
    setFloorIndoorTemperature (state, floorIndoorTemperature) {
      state.floorIndoorTemperature = floorIndoorTemperature
    },
  },
  actions: {
    async getIotDeviceTypes ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.APP_API_URL}iot/building/${payload.buildingNo}/floor/${payload.floorCode}/device-types`,
        )
        commit('setIotDeviceTypes', response?.data?.iotDeviceTypes)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getIotDevices ({ dispatch, commit }, payload) {
      let response = null
      try {
        commit('setIotDevices', [])
        response = await this._vm.get(
          `${this._vm.APP_API_URL}iot/building/${payload.buildingNo}/floor/${payload.floorCode}/device-type/${payload.deviceType}/devices`,
        )
        commit('setIotDevices', response?.data?.devices)
        commit(
          'setFloorIndoorTemperature',
          response?.data?.floorIndoorTemperature,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postIotWebhook ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}iot/webhook/${payload.webhook}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postIotIndoorLightingDeviceAction ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}iot/indoor-lighting-device/${payload.entityId}/${payload.action}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postBlindDeviceAction ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}iot/blind-device/${payload.entityId}/position/${payload.positionValue}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postHvacSystemDeviceAction ({ dispatch, commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.APP_API_URL}iot/hvac-system-device/${payload.entityId}/hvac-mode/${payload.hvacMode}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
