export default {
  namespaced: true,
  state: {
    historyList: [],
    invitationBuildings: [{
      displayBuildingNameKo: '',
      buildingNo: 0,
    }],
  },
  getters: {

  },
  mutations: {
    setInvitationHistory (state, data) {
      state.historyList = data
    },
    setInvitationBuildings (state, data) {
      state.invitationBuildings = data
    },
  },
  actions: {
    async getInvitationHistory ({ dispatch, commit }, payload) { // 초대 내역
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}invitation/history`, payload)
        commit('setInvitationHistory', response.data.invitationHistory)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInvitationBuildings ({ dispatch, commit }, payload) { // 초대 가능 층 목록(state.member.currentBuilding.buildingNo)
      let response
      try {
        response = await this._vm.get(`${this._vm.APP_API_URL}invitation/buildingds`)
        commit('setInvitationBuildings', response.data.invitationBuildings)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async setInvitationSend ({ dispatch, commit }, payload) { //
      let response
      try {
        response = await this._vm.post(`${this._vm.APP_API_URL}invitation/send`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 방문객 초대 재발송
    async postInvitationResend ({ dispatch, commit }, payload) { //
      let response
      try {
        response = await this._vm.post(`${this._vm.APP_API_URL}invitation/resend`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 방문객 초대취소
    async deleteInvitation ({ dispatch, commit }, payload) { //
      let response
      try {
        response = await this._vm.delete(`${this._vm.APP_API_URL}invitation/${payload.visitorNo}/visit`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
