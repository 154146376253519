
<template>
  <div class="popup-wrap">
    <div @click.self="close('outside')" class="bg" :class="{active}"></div>
    <div class="popup-container" ref="popup">
      <div class="popup-body">
        <div v-show="title" v-html="title" class="popup-title"></div>
        <div v-show="description" class="description" v-html="description"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      active: false,
    }
  },
  mounted () {
    if (this.$refs.popup.querySelector('button:last-child')) {
      this.$refs.popup.querySelector('button:last-child').focus()
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.active = true
      }, 1)
    })
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep sup {position: relative; top:0.2em; vertical-align: top; font-size: 0.6em; font-weight: 400;}
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 101;
  &.active {
    background-color: rgba(0,0,0,0.3);
  }
}

.popup-wrap {
  position: fixed !important;
  height: calc(var(--dvh) * 100); // fallback
  height: 100dvh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  text-align: center;
  z-index: 103;
  min-width: 280px;
  max-width: 280px;
  // width: fit-content;
  height: fit-content;

  border-radius: 6px;
  background-color: #F8F7EF;
  // box-shadow: 0 0 30px 0 #888;
  .popup-body {
    box-sizing: border-box;
    padding: 30px 20px;
    // min-width: 310px;
    .popup-title {
      padding-bottom: 8px;
      color: #000;
      text-align: center;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: -0.2px;
    }
    .description {
      color: #696969;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      white-space: pre-line;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button {
      width: 100%;
      padding: 17px 32px;
      border-radius: 0;
      border-right: 1px solid #4B4B4B;
      color: #FFFFFF;
      background: #1E1E1E;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      &:first-child {
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-right: 0;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
</style>
